<template>
  <div id="printInfo" style="margin-top: 10px">
    <div
      class="top"
      style="
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
        text-align: center;
      "
    >
      <span>山东格瑞德集团应聘人员登记表</span>
    </div>
    <div style="font-size: 15px; font-weight: bold; text-align: center">
      <span
        >以下信息仅限公司内部面试使用，我们将全力保护您的隐私，请您放心！</span
      >
    </div>
    <table
      id="newsPublish"
      class="newsTable"
      style="
        overflow-x: auto;
        width: 1050px;
        text-align: left;
        border-width: 2px;
      "
    >
        <tbody>
            <tr>
                <td class="left"
                    style="height: 33px; width: 120px; background-color: #FFFFFF">
                    填单日期
                </td>
                <td style="width: 160px">
                    <span id="ApplyDate">
                        {{
              dataForm.ApplyDate | formatStartTime
                        }}
                    </span>
                </td>
                <td class="left"
                    style="height: 33px; width: 100px; background-color: #FFFFFF">
                    姓名
                </td>
                <td style="width: 250px">
                    <span id="Name">{{ dataForm.Name }}</span>
                </td>
                <td class="left"
                    style="height: 33px; width: 120px; background-color: #FFFFFF">
                    出生年月
                </td>
                <td style="width: 200px">
                    <span id="Birthday">{{ dataForm.Birthday | formatStartTime }}</span>
                </td>
                <td class="left"
                    style="height: 33px; width: 100px; background-color: #FFFFFF">
                    住址电话
                </td>
                <td style="width: 200px">
                    <span id="AddressPhone">{{ dataForm.MobilePhone }}</span>
                </td>
                <td rowspan="6" style="text-align: center">
                    <img alt=""
                         :src="dataForm.Image_URL"
                         style="width: 130px; height: 140px"
                         id="IMG_URL" />
                </td>
            </tr>
            <tr>
                <td class="left"
                    style="height: 33px; width: 120px; background-color: #FFFFFF">
                    性别
                </td>
                <td style="width: 160px">
                    <span id="Sex">{{ dataForm.Sex }}</span>
                </td>
                <td class="left"
                    style="height: 33px; width: 100px; background-color: #FFFFFF">
                    民族
                </td>
                <td style="width: 200px">
                    <span id="Nationality">{{ dataForm.Nationality }}</span>
                </td>
                <td class="left"
                    style="height: 33px; width: 100px; background-color: #FFFFFF">
                    全日制学历
                </td>
                <td style="width: 200px">
                    <span id="FirstDiploma">{{ dataForm.FirstDiploma }}</span>
                </td>
                <td class="left"
                    style="height: 33px; width: 100px; background-color: #FFFFFF">
                    职称证书
                </td>
                <td style="width: 200px">
                    <span id="Position">{{ dataForm.Position }}</span>
                </td>
            </tr>
            <tr>
                <td class="left"
                    style="height: 33px; width: 120px; background-color: #FFFFFF">
                    外语级别
                </td>
                <td style="width: 160px">
                    <span id="LanguageLevel">{{ dataForm.LanguageLevel }}</span>
                </td>
                <td class="left"
                    style="height: 33px; width: 100px; background-color: #FFFFFF">
                    健康状况
                </td>
                <td style="width: 200px">
                    <span id="Health">{{ dataForm.Health }}</span>
                </td>
                <td class="left"
                    style="height: 33px; width: 100px; background-color: #FFFFFF">
                    非全日制学历
                </td>
                <td style="width: 200px">
                    <span id="TopDiploma">{{ dataForm.TopDiploma }}</span>
                </td>
                <td class="left"
                    style="height: 33px; width: 100px; background-color: #FFFFFF">
                    手机号码
                </td>
                <td style="width: 200px">
                    <span id="MobilePhone">{{ dataForm.MobilePhone }} </span>
                </td>
            </tr>
            <tr>
                <td class="left"
                    style="height: 33px; width: 120px; background-color: #FFFFFF">
                    政治面貌
                </td>
                <td style="width: 160px">
                    <span id="PoliticsStatus">{{ dataForm.PoliticsStatus }}</span>
                </td>
                <td class="left"
                    style="height: 33px; width: 100px; background-color: #FFFFFF">
                    户口性质
                </td>
                <td style="width: 200px">
                    <span id="NativeStatus">{{ dataForm.NativeStatus }}</span>
                </td>
                <td class="left"
                    style="height: 33px; width: 100px; background-color: #FFFFFF">
                    身份证号
                </td>
                <td colspan="3" style="width: 200px">
                    <span id="IDCardID">{{ dataForm.IDCard }}</span>
                </td>
            </tr>
            <tr>
                <td class="left"
                    style="height: 33px; width: 120px; background-color: #FFFFFF">
                    电子邮箱
                </td>
                <td colspan="2" style="width: 230px">
                    <span id="Email">{{ dataForm.Email }}</span>
                </td>
                <td class="left"
                    style="height: 33px; width: 100px; background-color: #FFFFFF">
                    通讯地址
                </td>
                <td colspan="4" style="width: 200px">
                    <span id="Address">{{ dataForm.Address }}</span>
                </td>
            </tr>
            <tr>
                <td class="left"
                    style="height: 25px; width: 100px; background-color: #FFFFFF">
                    籍贯
                </td>
                <td colspan="8" style="width: 550px; height: 25px">
                    <span id="E_user_address">{{ dataForm.NativePlace }}</span>
                </td>
            </tr>
            <tr>
                <td class="left"
                    style="height: 25px; width: 100px; background-color: #FFFFFF">
                    户籍地址
                </td>
                <td colspan="5" style="width: 400px">
                    <span id="E_cencus_address">{{ dataForm.IDAddress }}</span>
                </td>
                <td class="left"
                    style="height: 25px; width: 100px; background-color: #FFFFFF">
                    婚姻状况
                </td>
                <td colspan="2" style="width: 100px">
                    <span id="E_marry_status">{{ dataForm.MarriedStatus }}</span>
                </td>
            </tr>
            <tr>
                <td class="left"
                    style="height: 25px; width: 100px; background-color: #FFFFFF">
                    招聘来源
                </td>
                <td colspan="2" style="width: 200px">
                    <span id="apply_source">{{ dataForm.ApplySource }}</span>
                </td>
                <td class="left"
                    style="height: 25px; width: 100px; background-color: #FFFFFF">
                    紧急联系人/姓名
                </td>
                <td colspan="2" style="width: 200px">
                    <span id="connect_username">{{ dataForm.Emergency }}</span>
                </td>
                <td class="left"
                    style="height: 25px; width: 100px; background-color: #FFFFFF">
                    紧急联系人/电话
                </td>
                <td colspan="2" style="width: 255px">
                    <span id="connect_usertel">{{ dataForm.EmergencyPhone }}</span>
                </td>
            </tr>
            <tr>
                <td class="left"
                    style="height: 33px; width: 120px; background-color: #FFFFFF">
                    应聘岗位
                </td>
                <td colspan="5" style="width: 200px">
                    <span id="applyJob"></span>{{ dataForm.ApplyJob }}
                </td>
                <td class="left"
                    style="height: 33px; width: 100px; background-color: #FFFFFF">
                    可开始工作时间:
                </td>
                <td colspan="2" style="width: 200px">
                    <span id="applyWorkDate">
                        {{
              dataForm.ApplyStartDate | formatStartTime
                        }}
                    </span>
                </td>
            </tr>
            <tr>
                <td colspan="9"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    健康调查
                </td>
            </tr>
            <tr>
                <td colspan="3"
                    class="left"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    病史类型
                </td>
                <td colspan="2"
                    class="left"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    状态
                </td>
                <td colspan="4"
                    class="left"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    说明
                </td>
            </tr>
            <div v-if="dataForm.DiseaseArr != null" style="display: contents">
                <tr v-for="(item, i) in dataForm.DiseaseArr" :key="i">
                    <td colspan="3" style="width: 200px; height: 33px">
                        <span id="EduOneDate">{{ item.DiseaseType }}</span>
                    </td>
                    <td colspan="2" style="width: 200px; height: 33px">
                        <span id="GradauteSchoolOne">{{ item.IsYes }}</span>
                    </td>
                    <td colspan="4" style="width: 200px; height: 33px">
                        <span id="SpecialityOne">{{ item.Explain }}</span>
                    </td>
                </tr>
            </div>
            <tr>
                <td colspan="9"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    学习经历（高中、大学、在职进修）
                </td>
            </tr>
            <tr>
                <td colspan="3"
                    class="left"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    起止年月
                </td>
                <td colspan="2"
                    class="left"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    学校名称
                </td>
                <td colspan="2"
                    class="left"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    专业
                </td>
                <td colspan="1"
                    class="left"
                    style="
              height: 25px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    学历证明
                </td>
                <td colspan="1"
                    class="left"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    证明人电话
                </td>
            </tr>
            <div v-if="dataForm.EducatedArr != null" style="display: contents">
                <tr v-for="(item, i) in dataForm.EducatedArr" :key="i">
                    <td colspan="3" style="width: 200px; height: 33px">
                        <span id="EduOneDate">
                            {{
 item.EnterTime | formatStartTime
                            }}<span v-if="item.EnterTime != null">到</span>{{ item.GraduateTime | formatStartTime }}
                        </span>
                    </td>
                    <td colspan="2" style="width: 200px; height: 33px">
                        <span id="GradauteSchoolOne">{{ item.GradauteSchool }}</span>
                    </td>
                    <td colspan="2" style="width: 200px; height: 33px">
                        <span id="SpecialityOne">{{ item.Speciality }}</span>
                    </td>
                    <td colspan="1" style="width: 200px; height: 33px">
                        <span id="CertificateOne">{{ item.Certificate }}</span>
                    </td>
                    <td colspan="1" style="width: 200px; height: 33px">
                        <span id="VoucherOne">{{ item.Voucher }}</span>
                    </td>
                </tr>
            </div>
            <div v-if="dataForm.Educateds != null" style="display: contents">
                <tr v-for="(item, i) in dataForm.Educateds" :key="i">
                    <td colspan="3" style="width: 200px; height: 33px">
                        <span id="EduOneDate">
                            {{
 item.EnterTime | formatStartTime
                            }}<span v-if="item.EnterTime != null">到</span>{{ item.GraduateTime | formatStartTime }}
                        </span>
                    </td>
                    <td colspan="2" style="width: 200px; height: 33px">
                        <span id="GradauteSchoolOne">{{ item.GradauteSchool }}</span>
                    </td>
                    <td colspan="2" style="width: 200px; height: 33px">
                        <span id="SpecialityOne">{{ item.Speciality }}</span>
                    </td>
                    <td colspan="1" style="width: 200px; height: 33px">
                        <span id="CertificateOne">{{ item.Certificate }}</span>
                    </td>
                    <td colspan="1" style="width: 200px; height: 33px">
                        <span id="VoucherOne">{{ item.Voucher }}</span>
                    </td>
                </tr>
            </div>
            <tr>
                <td colspan="9"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    培训经历
                </td>
            </tr>

            <tr>
                <td colspan="3"
                    class="left"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    起止年月
                </td>
                <td colspan="2"
                    class="left"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    培训机构
                </td>
                <td colspan="2"
                    class="left"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    培训内容
                </td>
                <td colspan="2"
                    class="left"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    证书名称
                </td>
            </tr>
            <div v-if="dataForm.TrainedArr != null" style="display: contents">
                <tr v-for="(item, i) in dataForm.TrainedArr" :key="i">
                    <td colspan="3" style="width: 200px; height: 33px">
                        <span id="TrainDateOne">
                            {{
 item.StartTime | formatStartTime
                            }}<span v-if="item.StartTime != null">到</span>{{ item.EndTime | formatStartTime }}
                        </span>
                    </td>
                    <td colspan="2" style="width: 200px; height: 33px">
                        <span>{{ item.Institutions }}</span>
                    </td>
                    <td colspan="2" style="width: 200px; height: 33px">
                        <span>{{ item.Details }}</span>
                    </td>
                    <td colspan="2" style="width: 200px; height: 33px">
                        <span>{{ item.InCertificate }}</span>
                    </td>
                </tr>
            </div>
            <div v-if="dataForm.Traineds != null" style="display: contents">
                <tr v-for="(item, i) in dataForm.Traineds" :key="i">
                    <td colspan="3" style="width: 200px; height: 33px">
                        <span id="TrainDateOne">
                            {{
 item.StartTime | formatStartTime
                            }}<span v-if="item.StartTime != null">到</span>{{ item.EndTime | formatStartTime }}
                        </span>
                    </td>
                    <td colspan="2" style="width: 200px; height: 33px">
                        <span>{{ item.Institutions }}</span>
                    </td>
                    <td colspan="2" style="width: 200px; height: 33px">
                        <span>{{ item.Details }}</span>
                    </td>
                    <td colspan="2" style="width: 200px; height: 33px">
                        <span>{{ item.InCertificate }}</span>
                    </td>
                </tr>
            </div>

            <tr>
                <td colspan="9"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    工作经历
                </td>
            </tr>
            <tr>
                <td colspan="3"
                    class="left"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    起止年月
                </td>
                <td colspan="2"
                    class="left"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    工作单位
                </td>
                <td colspan="2"
                    class="left"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    职位/岗位
                </td>
                <td colspan="1"
                    class="left"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    离职原因
                </td>
                <td colspan="1"
                    class="left"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    证明人电话
                </td>
            </tr>
            <div v-if="dataForm.WorkedArr != null" style="display: contents">
                <tr v-for="(item, i) in dataForm.WorkedArr" :key="i">
                    <td colspan="3" style="width: 200px; height: 33px">
                        <span>
                            {{
 item.StartTime | formatStartTime
                            }}<span v-if="item.StartTime != null">到</span>{{ item.EndTime | formatStartTime }}
                        </span>
                    </td>
                    <td colspan="2" style="width: 200px; height: 33px">
                        <span>{{ item.WorkAddress }}</span>
                    </td>
                    <td colspan="2" style="width: 200px; height: 33px">
                        <span>{{ item.LeavingReason }}</span>
                    </td>
                    <td colspan="1" style="width: 200px; height: 33px">
                        <span>{{ item.BecaseWork }}</span>
                    </td>
                    <td colspan="1" style="width: 200px; height: 33px">
                        <span>{{ item.WorkVoucher }}</span>
                    </td>
                </tr>
            </div>
            <div v-if="dataForm.Workeds != null" style="display: contents">
                <tr v-for="(item, i) in dataForm.Workeds" :key="i">
                    <td colspan="3" style="width: 200px; height: 33px">
                        <span>
                            {{
 item.StartTime | formatStartTime
                            }}<span v-if="item.StartTime != null">到</span>{{ item.EndTime | formatStartTime }}
                        </span>
                    </td>
                    <td colspan="2" style="width: 200px; height: 33px">
                        <span>{{ item.WorkAddress }}</span>
                    </td>
                    <td colspan="2" style="width: 200px; height: 33px">
                        <span>{{ item.LeavingReason }}</span>
                    </td>
                    <td colspan="1" style="width: 200px; height: 33px">
                        <span>{{ item.BecaseWork }}</span>
                    </td>
                    <td colspan="1" style="width: 200px; height: 33px">
                        <span>{{ item.WorkVoucher }}</span>
                    </td>
                </tr>
            </div>
            <tr>
                <td colspan="9"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    家庭成员
                </td>
            </tr>
            <tr>
                <td colspan="3"
                    class="left"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    姓名
                </td>
                <td colspan="2"
                    class="left"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    与本人关系
                </td>
                <td colspan="2"
                    class="left"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    职位/岗位
                </td>
                <td colspan="1"
                    class="left"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    工作单位
                </td>
                <td colspan="1"
                    class="left"
                    style="
              height: 33px;
              width: 60px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    联系电话
                </td>
            </tr>
            <div v-if="dataForm.FamilyArr != null" style="display: contents">
                <tr v-for="(item, i) in dataForm.FamilyArr" :key="i">
                    <td colspan="3" style="width: 200px; height: 33px">
                        <span id="TrainDateOne">{{ item.FaName }}</span>
                    </td>
                    <td colspan="2" style="width: 200px; height: 33px">
                        <span id="InstitutionsOne">{{ item.Relationship }}</span>
                    </td>
                    <td colspan="2" style="width: 200px; height: 33px">
                        <span id="DetailsOne">{{ item.WorkDpt }}</span>
                    </td>
                    <td colspan="1" style="width: 200px; height: 33px">
                        <span id="InCertificateOne">{{ item.WorkUnits }}</span>
                    </td>
                    <td colspan="1" style="width: 200px; height: 33px">
                        <span id="WorkVoucherOne">{{ item.PhoneNo }}</span>
                    </td>
                </tr>
            </div>
            <div v-if="dataForm.Familys != null" style="display: contents">
                <tr v-for="(item, i) in dataForm.Familys" :key="i">
                    <td colspan="3" style="width: 200px; height: 33px">
                        <span id="TrainDateOne">{{ item.FaName }}</span>
                    </td>
                    <td colspan="2" style="width: 200px; height: 33px">
                        <span id="InstitutionsOne">{{ item.Relationship }}</span>
                    </td>
                    <td colspan="2" style="width: 200px; height: 33px">
                        <span id="DetailsOne">{{ item.WorkDpt }}</span>
                    </td>
                    <td colspan="1" style="width: 200px; height: 33px">
                        <span id="InCertificateOne">{{ item.WorkUnits }}</span>
                    </td>
                    <td colspan="1" style="width: 200px; height: 33px">
                        <span id="WorkVoucherOne">{{ item.PhoneNo }}</span>
                    </td>
                </tr>
            </div>
            <tr>
                <td style="
              height: 33px;
              width: 100px;
              background-color: #FFFFFF;
              text-align: center;
            ">
                    备注
                </td>
                <td colspan="8" style="width: 600px;height:80px">
                    <span id="txt_Remark">{{ dataForm.Remark }}</span>
                </td>
            </tr>
        </tbody>
    </table>
    <div id="printInfo2" style="margin-top: 380px">
      <table
        id="newsPublish2"
        class="newsTable"
        style="
          overflow-x: auto;
          width: 1050px;
          text-align: left;
          border-width: 2px;
        "
      >
        <tbody>
          <tr>
            <td
              colspan="9"
              style="
                height: 50px;
                width: 60px;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              知识产权情况调查
            </td>
          </tr>

          <tr>
            <td
              colspan="9"
              style="
                height: 440px;
                width: 60px;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              <table style="width: 100%; height: 100%">
                <tbody>
                    <tr>
                        <td style="
                        width: 40%;
                        height: 33px;
                        border: none;
                        text-align: left;
                      ">
                            在原单位工作的岗位是否涉及知识产权
                        </td>
                        <td v-show="dataForm.IsIPR" style="
                        width: 60%;
                        height: 33px;
                        border: none;
                        text-align: left;
                      ">
                            ■是&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;□否
                        </td>
                        <td v-show="!dataForm.IsIPR" style="
                        width: 60%;
                        height: 33px;
                        border: none;
                        text-align: left;
                      ">
                            □是&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;■否
                        </td>
                    </tr>
                  <tr>
                      <td colspan="9"
                          style="
                        vertical-align: top;
                        height: 110px;
                        width: 100%;
                        border: none;
                        text-align: left;
                      ">
                          请具体列举涉及的知识产权内容：{{ dataForm.IPRContent }}
                      </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 40%;
                        height: 33px;
                        border: none;
                        text-align: left;
                      "
                    >
                      是否与原单位签订过竞业限制条款：
                    </td>
                    <td v-show="dataForm.IsAgreement" style="
                        width: 60%;
                        height: 33px;
                        border: none;
                        text-align: left;
                      ">
                        ■是&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;□否
                    </td>
                    <td v-show="!dataForm.IsAgreement" style="
                        width: 60%;
                        height: 33px;
                        border: none;
                        text-align: left;
                      ">
                        □是&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;■否
                    </td>
                  </tr>
                  <tr>
                      <td colspan="9"
                          style="
                        vertical-align: top;
                        height: 60px;
                        width: 100%;
                        border: none;
                        text-align: left;
                      ">
                          请写明竟业限制条款的生效时间和年限：{{ dataForm.AgreementContent }}
                      </td>
                  </tr>
                  <tr>
                    <td
                      colspan="2"
                      style="
                        width: 80%;
                        border: none;
                        height: 33px;
                        text-align: left;
                      "
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本人在此声明，本人填写的资料都是真实的，如存在欺诈行为，公司有权解除劳动合同，并不负担任何赔偿责任；
                      给公司造成的<br />损害，有员工承担。
                    </td>
                  </tr>
                  <tr>
                    <td
                      style="
                        width: 70%;
                        height: 10px;
                        border: none;
                        text-align: left;
                      "
                    ></td>
                    <td
                      style="
                        width: 30%;
                        height: 33px;
                        border: none;
                        text-align: left;
                      "
                    >
                      声明人签字：
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td
              colspan="9"
              style="
                height: 100px;
                width: 60px;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              <table style="width: 100%; height: 100%">
                <tbody>
                  <tr>
                    <td
                      style="
                        vertical-align: top;
                        width: 40%;
                        border: none;
                        text-align: left;
                      "
                    >
                      知识产权信息及其他背景情况核实:
                    </td>
                    <td
                      style="
                        vertical-align: bottom;
                        border: none;
                        width: 60%;
                        text-align: center;
                      "
                    >
                      调查人:
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td
              colspan="9"
              style="
                height: 50px;
                width: 60px;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              以下项目由公司填写
            </td>
          </tr>
          <tr>
            <td
              colspan="1"
              style="
                height: 33px;
                width: 18%;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              工作部门
            </td>
            <td
              colspan="2"
              style="
                height: 33px;
                width: 32%;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              <span id="dpt_name"></span>
            </td>
            <td
              colspan="1"
              style="
                height: 33px;
                width: 18%;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              工作岗位
            </td>
            <td
              colspan="2"
              style="
                height: 33px;
                width: 32%;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              <span id="job_name"></span>
            </td>
          </tr>
          <tr>
            <td
              colspan="1"
              style="
                height: 33px;
                width: 18%;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              到职日期
            </td>
            <td
              colspan="2"
              style="
                height: 33px;
                width: 32%;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              <span id="AvailableDate"></span>
            </td>
            <td
              colspan="1"
              style="
                height: 33px;
                width: 18%;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              试用期
            </td>
            <td
              colspan="2"
              style="
                height: 33px;
                width: 32%;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              <span id="Probation"></span>
            </td>
          </tr>
          <tr>
            <td
              colspan="1"
              style="
                height: 33px;
                width: 18%;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              工资待遇
            </td>
            <td
              colspan="5"
              style="height: 33px; width: 82%; background-color: #FFFFFF"
            >
              <table style="width: 100%; height: 100%">
                <tbody>
                  <tr>
                    <td style="width: 40%; border: none">
                      试用期:<span id="AppTreatment"></span>
                    </td>
                    <td style="border: none; width: 60%; text-align: left">
                      转正:<span id="Treatment"></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td
              colspan="1"
              style="
                height: 33px;
                width: 18%;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              其他待遇
            </td>
            <td
              colspan="5"
              style="height: 33px; width: 82%; background-color: #FFFFFF"
            >
              <span id="OtherTreatment"></span>
            </td>
          </tr>
          <tr>
            <td
              colspan="1"
              style="
                height: 65px;
                width: 18%;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              部门负责人意见
            </td>
            <td
              colspan="2"
              style="height: 65px; width: 32%; background-color: #FFFFFF"
            >
              <span id="DPTLeader"></span>
            </td>
            <td
              colspan="1"
              style="
                height: 65px;
                width: 18%;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              部门负责人审批时间
            </td>
            <td
              colspan="2"
              style="height: 65px; width: 32%; background-color: #FFFFFF"
            >
              <span id="DPTLeaderSPTime"></span>
            </td>
          </tr>
          <tr>
            <td
              colspan="1"
              style="
                height: 65px;
                width: 18%;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              分管领导意见
            </td>
            <td
              colspan="2"
              style="height: 65px; width: 32%; background-color: #FFFFFF"
            >
              <span id="FgLeader"></span>
            </td>
            <td
              colspan="1"
              style="
                height: 65px;
                width: 18%;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              分管领导审批时间
            </td>
            <td
              colspan="2"
              style="height: 65px; width: 32%; background-color: #FFFFFF"
            >
              <span id="FgLeaderSPTime"></span>
            </td>
          </tr>
          <tr>
            <td
              colspan="1"
              style="
                height: 65px;
                width: 18%;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              人力资源部意见
            </td>
            <td
              colspan="2"
              style="height: 65px; width: 32%; background-color: #FFFFFF"
            >
              <span id="HrLeader"></span>
            </td>
            <td
              colspan="1"
              style="
                height: 65px;
                width: 18%;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              人力资源部审批时间
            </td>
            <td
              colspan="2"
              style="height: 65px; width: 32%; background-color: #FFFFFF"
            >
              <span id="HrLeaderSPTime"></span>
            </td>
          </tr>
          <tr>
            <td
              colspan="1"
              style="
                height: 65px;
                width: 18%;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              行政总监意见
            </td>
            <td
              colspan="2"
              style="height: 65px; width: 32%; background-color: #FFFFFF"
            >
              <span id="Manage"></span>
            </td>
            <td
              colspan="1"
              style="
                height: 65px;
                width: 18%;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              行政总监审批时间
            </td>
            <td
              colspan="2"
              style="height: 65px; width: 32%; background-color: #FFFFFF"
            >
              <span id="ManageSPTime"></span>
            </td>
          </tr>
          <tr>
            <td
              colspan="1"
              style="
                height: 65px;
                width: 18%;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              集团领导意见
            </td>
            <td
              colspan="2"
              style="height: 65px; width: 32%; background-color: #FFFFFF"
            >
              <span id="Chairman"></span>
            </td>
            <td
              colspan="1"
              style="
                height: 65px;
                width: 18%;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              董事长审批时间
            </td>
            <td
              colspan="2"
              style="height: 65px; width: 32%; background-color: #FFFFFF"
            >
              <span id="ChairmanSPTime"></span>
            </td>
          </tr>
          <tr>
            <td
              colspan="1"
              style="
                height: 65px;
                width: 18%;
                background-color: #FFFFFF;
                text-align: center;
              "
            >
              备注
            </td>
            <td
              colspan="5"
              style="height: 65px; width: 82%; background-color: #FFFFFF"
            ></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import util from "../../util/date";
import { getUserBaseInfo, GetAtPresentBaseInfo } from "../api/hr";
export default {
  data() {
    // <tr v-for =" (item,i) in dataForm.DiseaseArr" :key="i">
    //         <td colspan="3" style="width:200px; height:40px;"><span id="EduOneDate">{{item.DiseaseType}}</span></td>
    //         <td colspan="2" style="width:200px; height:40px;"><span id="GradauteSchoolOne">{{item.IsYes}}</span></td>
    //         <td colspan="4" style="width:200px; height:40px;"><span id="SpecialityOne">{{item.Explain}}</span></td>

    //     </tr>
    return {
      dataForm: {
        ApplyDate: null,
        DiseaseArr: [
          {
            DiseaseType: "有无残疾",
          },
          {
            DiseaseType: "有无先天疾病",
          },
          {
            DiseaseType: "有无职业病史",
          },
          {
            DiseaseType: "有无家族病史",
          },
          {
            DiseaseType: "有无传染病史",
          },
        ],
        EducatedArr: [{}, {}, {}],
        TrainedArr: [{}, {}, {}],
        WorkedArr: [{}, {}, {}],
        FamilyArr: [{}, {}, {}],
      },
    };
  },
  methods: {
    formatStartTime: function (row, colume) {
      var date = row[colume.property];
      if (date == undefined) {
        return "";
      }
      return util.formatDate.format(new Date(date), "yyyy-MM-dd");
    },
      getQuery() {
          let type = this.$route.query.Type;
          
          if (type == "Archuves") //  档案
          {
              let paraDA = {
                  userId: this.$route.query.userId,
              }
              console.log(paraDA);
              GetAtPresentBaseInfo(paraDA).then((res) => {
                  this.dataForm = res.data.response;
              });
          }
          else {
              let paraYP = {
                  id: this.$route.query.id,
              };
              getUserBaseInfo(paraYP).then((res) => {
                  this.dataForm = res.data.response;
              });
          }
    },
  },
  filters: {
    formatStartTime: function (value) {
      if (value) {
        return util.formatDate.format(new Date(value), "yyyy-MM-dd");
      }
    },
  },

  mounted() {
    this.getQuery();
  },
};
</script>
<style scoped>
.newsTable {
  border: solid 1px #DFDFDF;
  border-spacing: 0;
  clear: both;
  margin: 0;
  -moz-border-radius: 3px;
  -khtml-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  border-collapse: collapse;
  border-color: black;
}
.newsTable td.left {
  text-align: left;
  line-height: 2em;
  font-size: 13px;
  font-weight: bold;
  color: #333;
  border: solid 1px #DFDFDF;
  background-color: #F9F9F9;
  border-color: black;
}
.newsTable td {
  border: solid 1px #DFDFDF;
  font-size: 16px;
  padding-left: 3px;
  font-weight: bold;
  vertical-align: middle;
  border-color: black;
}
</style>